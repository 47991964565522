#data-grid-component > .data-grid-header .MuiDataGrid-columnHeaders {
  background-color: #1976d2;
  color: #fff;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

#data-grid-component > .data-grid-header .MuiDataGrid-columnHeaders .MuiButtonBase-root > .MuiSvgIcon-root{
  color: #fff;
}

#data-grid-component > .data-grid-header .MuiDataGrid-columnHeaders .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  background-color: #1976d2;
  color: #fff;
}

#data-grid-component > .data-grid-header .MuiDataGrid-columnHeaders .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root {
  background-color: #1976d2;
  color: #fff;
}

#data-grid-component > .data-grid-header .MuiDataGrid-columnHeaders .MuiDataGrid-pinnedColumnHeaders {
  background-color: #1976d2;
  color: #fff;
}

#data-grid-component .MuiDataGrid-cell {
  font-size: .8rem; 
}

.MuiDataGrid-panel.css-n3z9fz-MuiPopper-root-MuiDataGrid-panel.MuiPopper-root
{
  transform: translateX(100vw) translateX(-540px) translateY(225px) !important;
}

#data-grid-component > .data-grid-header .MuiDataGrid-toolbarContainer
{
  display: flex;
  flex-direction: row-reverse;
  background-color: #1976d2;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  padding-right: 10px;
}

#data-grid-component > .data-grid-header .MuiDataGrid-toolbarContainer > .MuiButtonBase-root
{
  color: #fff;
}

#data-grid-component .MuiDataGrid-row > .MuiDataGrid-cellCheckbox > .MuiCheckbox-root {
  transform: scale(.8);
}

.odd {
  background-color: #bcd9f73f;
}