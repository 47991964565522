body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navigation-link {
  display: flex;
  padding-left: 10px;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 21px;
  margin-top: -25px;
  text-decoration: underline;
}

input::placeholder {
  font-style: normal;
  opacity: 1 !important;
}

.custom-tabs {
  border-bottom: 1px solid #e8e8e8;
}

.custom-tab {
  text-transform: none !important;
}

.custom-table-cell {
  padding-right: 30px !important;
  border-bottom: none !important;
}

@font-face {
  font-family: 'Metronic Pro';
  src: url('./statics/font/MetronicPro-SemiBold.eot');
  src: local('Metronic Pro SemiBold'), local('MetronicPro-SemiBold'),
      url('./statics/font/MetronicPro-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('./statics/font/MetronicPro-SemiBold.woff2') format('woff2'),
      url('./statics/font/MetronicPro-SemiBold.woff') format('woff'),
      url('./statics/font/MetronicPro-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Metronic Pro Air';
  src: url('./statics/font/MetronicPro-Thin.eot');
  src: local('Metronic Pro Thin'), local('MetronicPro-Thin'),
      url('./statics/font/MetronicPro-Thin.eot?#iefix') format('embedded-opentype'),
      url('./statics/font/MetronicPro-Thin.woff2') format('woff2'),
      url('./statics/font/MetronicPro-Thin.woff') format('woff'),
      url('./statics/font/MetronicPro-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Metronic Pro';
  src: url('./statics/font/MetronicPro-LightItalic.eot');
  src: local('Metronic Pro Light Italic'), local('MetronicPro-LightItalic'),
      url('./statics/font/MetronicPro-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('./statics/font/MetronicPro-LightItalic.woff2') format('woff2'),
      url('./statics/font/MetronicPro-LightItalic.woff') format('woff'),
      url('./statics/font/MetronicPro-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Metronic Pro Air';
  src: url('./statics/font/MetronicPro-ThinItalic.eot');
  src: local('Metronic Pro Thin Italic'), local('MetronicPro-ThinItalic'),
      url('./statics/font/MetronicPro-ThinItalic.eot?#iefix') format('embedded-opentype'),
      url('./statics/font/MetronicPro-ThinItalic.woff2') format('woff2'),
      url('./statics/font/MetronicPro-ThinItalic.woff') format('woff'),
      url('./statics/font/MetronicPro-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Metronic Pro';
  src: url('./statics/font/MetronicPro-Bold.eot');
  src: local('Metronic Pro Bold'), local('MetronicPro-Bold'),
      url('./statics/font/MetronicPro-Bold.eot?#iefix') format('embedded-opentype'),
      url('./statics/font/MetronicPro-Bold.woff2') format('woff2'),
      url('./statics/font/MetronicPro-Bold.woff') format('woff'),
      url('./statics/font/MetronicPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Metronic Pro';
  src: url('./statics/font/MetronicPro-Black.eot');
  src: local('Metronic Pro Black'), local('MetronicPro-Black'),
      url('./statics/font/MetronicPro-Black.eot?#iefix') format('embedded-opentype'),
      url('./statics/font/MetronicPro-Black.woff2') format('woff2'),
      url('./statics/font/MetronicPro-Black.woff') format('woff'),
      url('./statics/font/MetronicPro-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Metronic Pro';
  src: url('./statics/font/MetronicPro-Light.eot');
  src: local('Metronic Pro Light'), local('MetronicPro-Light'),
      url('./statics/font/MetronicPro-Light.eot?#iefix') format('embedded-opentype'),
      url('./statics/font/MetronicPro-Light.woff2') format('woff2'),
      url('./statics/font/MetronicPro-Light.woff') format('woff'),
      url('./statics/font/MetronicPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Metronic Pro';
  src: url('./statics/font/MetronicPro-SemiBoldItalic.eot');
  src: local('Metronic Pro SemiBold Italic'), local('MetronicPro-SemiBoldItalic'),
      url('./statics/font/MetronicPro-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('./statics/font/MetronicPro-SemiBoldItalic.woff2') format('woff2'),
      url('./statics/font/MetronicPro-SemiBoldItalic.woff') format('woff'),
      url('./statics/font/MetronicPro-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Metronic Pro';
  src: url('./statics/font/MetronicPro-BlackItalic.eot');
  src: local('Metronic Pro Black Italic'), local('MetronicPro-BlackItalic'),
      url('./statics/font/MetronicPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('./statics/font/MetronicPro-BlackItalic.woff2') format('woff2'),
      url('./statics/font/MetronicPro-BlackItalic.woff') format('woff'),
      url('./statics/font/MetronicPro-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Metronic Pro';
  src: url('./statics/font/MetronicPro-BoldItalic.eot');
  src: local('Metronic Pro Bold Italic'), local('MetronicPro-BoldItalic'),
      url('./statics/font/MetronicPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('./statics/font/MetronicPro-BoldItalic.woff2') format('woff2'),
      url('./statics/font/MetronicPro-BoldItalic.woff') format('woff'),
      url('./statics/font/MetronicPro-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Metronic Pro';
  src: url('./statics/font/MetronicPro-Italic.eot');
  src: local('Metronic Pro Italic'), local('MetronicPro-Italic'),
      url('./statics/font/MetronicPro-Italic.eot?#iefix') format('embedded-opentype'),
      url('./statics/font/MetronicPro-Italic.woff2') format('woff2'),
      url('./statics/font/MetronicPro-Italic.woff') format('woff'),
      url('./statics/font/MetronicPro-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Metronic Pro';
  src: url('./statics/font/MetronicPro.eot');
  src: local('Metronic Pro'), local('MetronicPro'),
      url('./statics/font/MetronicPro.eot?#iefix') format('embedded-opentype'),
      url('./statics/font/MetronicPro.woff2') format('woff2'),
      url('./statics/font/MetronicPro.woff') format('woff'),
      url('./statics/font/MetronicPro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

