.MuiDrawer-root.MuiDrawer-docked > .MuiPaper-root.MuiPaper-elevation > .css-ls5q34 {
    background-color: #1976d2;
}

.MuiDrawer-root.MuiDrawer-docked > .MuiPaper-root.MuiPaper-elevation > .css-ls5q34 > .MuiButtonBase-root.MuiIconButton-root {
    color: white;
}

.MuiDrawer-root.MuiDrawer-docked > .MuiPaper-root.MuiPaper-elevation > .MuiListItem-root.MuiListItem-gutters > .MuiButtonBase-root.MuiListItemButton-root .MuiSvgIcon-root {
    margin-right: 8px;
}